<template>
  <div>
    <div class="d-flex flex-column">
      <BankList />

      <MainButton
        :disabled="bankNotSelected"
        routeTo="StandingOrderConfirmation"
      >
        continue
      </MainButton>
    </div>
  </div>
</template>

<script>
import BankList from '@/components/BankList.vue';

export default {
  name: 'standingOrderSelectBank',
  components: {
    BankList
  },
  computed: {
    bankNotSelected() {
      if (this.$store.state.selectedBank) return false
      return true
    }
  }
}
</script>

<style scoped>
</style>
