<template>
  <div>
    <div class="pa-1" v-if="getBankLogo(bank)">
      <v-img
        class="bank-logo-img"
        width="100%"
        height="70"
        contain
        :alt="`${bank.BankName}`"
        :src="getBankLogo(bank)"
      ></v-img>
    </div>
    <p
      v-else
      class="ma-0 text-caption"
      :class="bank.BankName === selectedBank.BankName ? 'white--text' : ''"
    >
      {{ bank.BankName }}
    </p>
    <!-- <v-icon v-else large>mdi-shield</v-icon> -->
  </div>
</template>

<script>
export default {
  name: "bankLogo",
  props: {
    bank: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bankLogoHeight: {
      type: [Number, String],
      default: 50,
    },
  },
  methods: {
    getBankLogo(bank) {
      // const firstWord = bank.BankName.split(" ")[0]
      // const logoSrc = require(`@/assets/BankLogos/${firstWord}.svg`)
      try {
        if (bank.BankName === "Bank of Ireland 365 online") {
          const logoSrc = require(`@/assets/BankLogos/BOI.png`);
          return logoSrc;
        }
        if (bank.BankName === "Allied Irish Bank (GB)") {
          const logoSrc = require(`@/assets/BankLogos/AIB.png`);
          return logoSrc;
        }
        if (
          bank.BankName === "Bank of Scotland Business" ||
          bank.BankName === "Bank of Scotland Personal"
        ) {
          const logoSrc = require(`@/assets/BankLogos/BOS.png`);
          return logoSrc;
        }
        if (bank.BankName === "Royal Bank of Scotland") {
          const logoSrc = require(`@/assets/BankLogos/RBS.png`);
          return logoSrc;
        }
        if (
          bank.BankName === "Barclays Business" ||
          bank.BankName === "Barclays Personal"
        ) {
          const logoSrc = require(`@/assets/BankLogos/Barclays.png`);
          return logoSrc;
        }
        if (bank.BankName === "Halifax") {
          const logoSrc = require(`@/assets/BankLogos/Halifax.png`);
          return logoSrc;
        }
        if (
          bank.BankName === "Danske Business" ||
          bank.BankName === "Danske Personal"
        ) {
          const logoSrc = require(`@/assets/BankLogos/Danske.png`);
          return logoSrc;
        }
        if (
          bank.BankName === "HSBC Business" ||
          bank.BankName === "HSBC Personal" ||
          bank.BankName === "HSBC Kinetic"
        ) {
          const logoSrc = require(`@/assets/BankLogos/HSBC.png`);
          return logoSrc;
        }
        if (
          bank.BankName === "Lloyds Business" ||
          bank.BankName === "Lloyds Personal"
        ) {
          const logoSrc = require(`@/assets/BankLogos/Lloyds.png`);
          return logoSrc;
        }
        if (bank.BankName === "Nationwide") {
          const logoSrc = require(`@/assets/BankLogos/Nationwide.png`);
          return logoSrc;
        }
        if (bank.BankName === "NatWest") {
          const logoSrc = require(`@/assets/BankLogos/NatWest.png`);
          return logoSrc;
        }
        if (bank.BankName === "first direct") {
          const logoSrc = require(`@/assets/BankLogos/First.png`);
          return logoSrc;
        }
        if (bank.BankName === "Monzo") {
          const logoSrc = require(`@/assets/BankLogos/Monzo.png`);
          return logoSrc;
        }
        if (bank.BankName === "Revolut") {
          const logoSrc = require(`@/assets/BankLogos/Revolut.png`);
          return logoSrc;
        }
        if (bank.BankName === "Santander") {
          const logoSrc = require(`@/assets/BankLogos/Santander.png`);
          return logoSrc;
        }
        if (bank.BankName === "TSB") {
          const logoSrc = require(`@/assets/BankLogos/TSB.png`);
          return logoSrc;
        }
        if (bank.BankName === "M&S Bank") {
          const logoSrc = require(`@/assets/BankLogos/M&S.png`);
          return logoSrc;
        }
        if (bank.BankName === "Wise") {
          const logoSrc = require(`@/assets/BankLogos/Wise.png`);
          return logoSrc;
        }
        if (bank.BankName === "Ulster Bank (UK)") {
          const logoSrc = require(`@/assets/BankLogos/Ulster.png`);
          return logoSrc;
        }
        if (bank.BankName === "Starling") {
          const logoSrc = require(`@/assets/BankLogos/Starling.png`);
          return logoSrc;
        }
        if (bank.BankName === "Virgin Money") {
          const logoSrc = require(`@/assets/BankLogos/Virgin.png`);
          return logoSrc;
        }
        if (bank.BankName === "Tide") {
          const logoSrc = require(`@/assets/BankLogos/Tide.png`);
          return logoSrc;
        }
        if (bank.BankName === "Mettle") {
          const logoSrc = require(`@/assets/BankLogos/Mettle.png`);
          return logoSrc;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style>
</style>