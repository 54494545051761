<template>
  <div>
    <div class="payment-method-title mb-0">
      <p
        tabindex="0"
        aria-label="Select your bank"
        class="my-0 text-center text-uppercase"
      >
        select your bank
      </p>
    </div>

    <p tabindex="0" class="text-center text-body-2 mt-sm-5 mb-sm-8">
      You will be securely redirected to your bank to authenticate this payment.
    </p>

    <div
      v-if="$store.state.bankList"
      class="grey lighten-5 pa-1 d-flex flex-wrap justify-center"
    >
      <div v-for="(bank, index) in $store.state.bankList" :key="index">
        <v-card
          v-if="bankOfferService(bank)"
          @click="setBank(bank)"
          @keyup.enter="setBank(bank)"
          width="100"
          height="110"
          class="ma-2 pb-3 text-center d-flex flex-column justify-center"
          :ripple="ripple"
        >
          <BankLogo :bank="bank" :bankLogoHeight="bankLogoHeight" />

          <div class="bank-description">
            <div
              class="inner-wrapper"
              :class="
                bank.BankName === selectedBank.BankName
                  ? 'primary'
                  : 'secondary'
              "
            >
              <p v-if="bank.BankName === 'Bank of Scotland Business'">
                Business
              </p>
              <p v-else-if="bank.BankName === 'Bank of Scotland Personal'">
                Personal
              </p>

              <p v-else-if="bank.BankName === 'Barclays Business'">Business</p>
              <p v-else-if="bank.BankName === 'Barclays Personal'">Personal</p>

              <p v-else-if="bank.BankName === 'Danske Business'">Business</p>
              <p v-else-if="bank.BankName === 'Danske Personal'">Personal</p>

              <p v-else-if="bank.BankName === 'HSBC Business'">Business</p>
              <p v-else-if="bank.BankName === 'HSBC Personal'">Personal</p>

              <p v-else-if="bank.BankName === 'Lloyds Business'">Business</p>
              <p v-else-if="bank.BankName === 'Lloyds Personal'">Personal</p>

              <p v-else-if="bank.BankName === 'Bank of Ireland 365 online'">
                Bank of Ireland
              </p>
              <p v-else-if="bank.BankName === 'Royal Bank of Scotland'">RBS</p>
              <p v-else-if="bank.BankName === 'Allied Irish Bank (GB)'">
                AIB (GB)
              </p>

              <p v-else>{{ bank.BankName }}</p>
            </div>
          </div>
        </v-card>
      </div>
      <v-btn
        class="my-5"
        block
        @click="toBankNotShown"
        @keyup.enter="toBankNotShown"
        color="secondary lighten-2 my-5 mx-12"
        text
        width="300"
        >Bank not shown?</v-btn
      >
    </div>

    <div class="mt-6 mb-sm-10">
      <p
        tabindex="0"
        v-if="$store.state.selectedBank.BankName"
        class="my-0 text-center text-capitalize"
      >
        Your bank:
      </p>
      <transition name="flip-vertically" mode="out-in">
        <p
          tabindex="0"
          :key="$store.state.selectedBank.BankName"
          v-if="$store.state.selectedBank.BankName"
          class="
            my-0
            text-h4
            primary--text
            text-center text-capitalize
            font-weight-black
          "
        >
          {{ $store.state.selectedBank.BankName }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import BankLogo from "@/components/BankLogo.vue";

export default {
  name: "bankList",
  components: {
    BankLogo,
  },
  created() {
    if (this.$store.state.selectedBank)
      this.selectedBank = this.$store.state.selectedBank;
    if (!this.$store.state.bankList) {
      const bankList = JSON.parse(sessionStorage.getItem("modulrBankList"));
      this.$store.commit("SET_BANK_LIST", bankList);
      this.selectedBank = this.$store.state.selectedBank;
    }
  },
  data() {
    return {
      selectedBank: "",
      bankLogoHeight: 50,
      ripple: false,
    };
  },
  methods: {
    bankOfferService(bank) {
      // render banks that off relevant service
      if (this.$store.state.openUrlOneOffPayment) {
        if (bank.SinglePaymentSupported) return true;
        return false;
      } else if (
        this.$store.state.paymentDetails.Request.Frequency === "OneOff"
      ) {
        if (bank.SinglePaymentSupported) return true;
        return false;
      } else {
        if (bank.StandingOrderSupported) return true;
        return false;
      }
    },
    setBank(bank) {
      this.selectedBank = bank;
      this.$store.commit("SET_SELECTED_BANK", bank);
      setTimeout(() => {
        this.$vuetify.goTo(10000);
      }, 500);
    },
    toBankNotShown() {
      this.$router.push({ name: "BankNotShown" });
    },
  },
};
</script>

<style scoped>
.bank-description {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.bank-description .inner-wrapper {
  width: 100%;
  margin: 0 auto;
  border-radius: 2px;
  padding: 1px 0;
}

.bank-description p {
  margin: 0;
  color: white;
  font-size: 10px;
}
</style>